import {cssClasses} from "../../util";

const classNames = cssClasses({
  footer: {
    background: '#f3f3f3',
    marginTop: '3rem',
    padding: '1rem 0',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    display: 'block'
  }
});

export function Footer() {
  return (
    <footer className={classNames.footer}>
      <div className='container'>
        <a href='/#/' className='logo-font'>
          conduit
        </a>
        <span className='attribution'>
          An interactive learning project from <a href='https://thinkster.io'>Thinkster</a>. Code &amp; design licensed
          under MIT.
        </span>
      </div>
    </footer>
  );
}
