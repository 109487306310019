import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { Article } from '../../types/article';
import {cssClasses} from "../../util";

const classNames = cssClasses({
  headline: {
    fontWeight: 600,
    fontSize: '1.5rem',
    marginBottom: '3px'
  },
  text: {
    fontWeight: 300,
    color: '#999',
    marginBottom: '15px',
    fontSize: '1rem',
    lineHeight: '1.3rem'
  },
  link: {
    color: 'inherit'
  },
  readMore: {
    fontSize: '.8rem',
    fontWeight: 300,
    color: '#bbb',
    verticalAlign: 'middle'
  },
  tagList: {
    float: 'right',
    maxWidth: '50%'
  }
});

export function ArticlePreview({
  article: {
    createdAt,
    favorited,
    favoritesCount,
    slug,
    title,
    description,
    tagList,
    author: { image, username },
  },
  isSubmitting,
  onFavoriteToggle,
}: {
  article: Article;
  isSubmitting: boolean;
  onFavoriteToggle?: () => void;
}) {
  return (
    <div className='article-preview'>
      <div className='article-meta'>
        <Link to={`/profile/${username}`} className='author'>
          <img src={image || undefined} />
        </Link>
        <div className='info'>
          <Link to={`/profile/${username}`} className='author'>
            {username}
          </Link>
          <span className='date'>{format(createdAt, 'PP')}</span>
        </div>
        <button
          className={`btn btn-sm pull-xs-right ${favorited ? 'btn-primary' : 'btn-outline-primary'}`}
          disabled={isSubmitting}
          onClick={onFavoriteToggle}
        >
          <i className='ion-heart'></i> {favoritesCount}
        </button>
      </div>
      <h1 className={classNames.headline}><a className={classNames.link} href={`/#/article/${slug}`}>{title}</a></h1>
      <p className={classNames.text}><a className={classNames.link} href={`/#/article/${slug}`}>{description}</a></p>
      <span className={classNames.readMore}><a className={classNames.link} href={`/#/article/${slug}`}>Read more...</a></span>
      <span className={classNames.tagList}>
        <TagList tagList={tagList} />
      </span>
    </div>
  );
}

export function TagList({ tagList }: { tagList: string[] }) {
  return (
    <ul className='tag-list'>
      {tagList.map((tag) => (
        <li key={tag} className='tag-default tag-pill tag-outline'>
          {tag}
        </li>
      ))}
    </ul>
  );
}
