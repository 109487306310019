import { css } from '@emotion/css';
import { Fragment, ReactChild, ReactFragment, ReactPortal, useEffect, useState } from 'react';
import { HashRouter, NavLink } from 'react-router-dom';
import { useStore } from '../../state/storeHooks';
import { User } from '../../types/user';

export function Header() {
    const { user } = useStore(({ app }) => app);


    return (
        <nav className='navbar navbar-light'>
            <div className='container'>
                <a className='navbar-brand' href='/#/'>
                    conduit
                </a>
                <SelectLanguage />
                <ul className='nav navbar-nav pull-xs-right'>
                    <HashRouter>

                        <NavItem text='Home' href='/' />
                        {user.match({
                            none: () => <GuestLinks />,
                            some: (user) => <UserLinks user={user} />,
                        })}
                    </HashRouter>
                </ul>
            </div>
        </nav>
    );
}

function NavItem({ text, href, icon }: { text: string; href: string; icon?: string }) {
    return (
        <li className='nav-item'>
            <NavLink exact to={href} activeClassName='active' className='nav-link'>
                {icon && <i className={icon}></i>}&nbsp;
                {text}
            </NavLink>
        </li>
    );
}

function GuestLinks() {
    return (
        <Fragment>
            <NavItem text='Sign in' href='/login' />
            <NavItem text='Sign up' href='/register' />
        </Fragment>
    );
}

function UserLinks({ user: { username } }: { user: User }) {
    return (
        <Fragment>
            <NavItem text='New Article' href='/editor' icon='ion-compose' />
            <NavItem text='Settings' href='/settings' icon='ion-gear-a' />
            <NavItem text={`${username}`} href={`/profile/${username}`} />
        </Fragment>
    );
}

function SelectLanguage() {
    const [language, setLanguage] = useState("de");
    const imageclass = css({ marginRight: "4px", height: "16px" });
    const unselected = css({ filter: "grayscale()" })
    return (
        <Fragment>
            <img alt="changeLanguage1" tabIndex={1} onClick={() => setLanguage("de")} className={`${imageclass} ${language === "de" ? "" : unselected}`} src="data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg xmlns='http://www.w3.org/2000/svg' width='1000' height='600' viewBox='0 0 5 3'%3E%3Cdesc%3EFlag of Germany%3C/desc%3E%3Crect id='black_stripe' width='5' height='3' y='0' x='0' fill='%23000'/%3E%3Crect id='red_stripe' width='5' height='2' y='1' x='0' fill='%23D00'/%3E%3Crect id='gold_stripe' width='5' height='1' y='2' x='0' fill='%23FFCE00'/%3E%3C/svg%3E" ></img>
            <img alt="changeLanguage2" tabIndex={0} onClick={() => setLanguage("us")} className={`${imageclass} ${language === "de" ? unselected : ""}`} src="data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='1235' height='650' viewBox='0 0 36.1 19'%3E%3Cdefs%3E%3Cpath id='s' transform='scale(0.58515)' fill='%23fff' d='M0,-1 L0.58779,0.80902 L-0.95106,-0.30902 L0.95106,-0.30902 L-0.58779,0.80902z'/%3E%3Cg id='s8'%3E%3Cg id='s7'%3E%3Cuse xlink:href='%23s' x='.9025' y='0.8501'/%3E%3Cuse xlink:href='%23s' x='2.7075' y='0.8501'/%3E%3Cuse xlink:href='%23s' x='4.5125' y='0.8501'/%3E%3Cuse xlink:href='%23s' x='6.3175' y='0.8501'/%3E%3Cuse xlink:href='%23s' x='8.1225' y='0.8501'/%3E%3Cuse xlink:href='%23s' x='9.9275' y='0.8501'/%3E%3Cuse xlink:href='%23s' x='11.7325' y='0.8501'/%3E%3C/g%3E%3Cuse xlink:href='%23s' x='13.5375' y='0.8501'/%3E%3C/g%3E%3Cg id='u'%3E%3Cuse xlink:href='%23s8'/%3E%3Cuse xlink:href='%23s7' x='0.9025' y='1.705'/%3E%3Cuse xlink:href='%23s7' x='0.9025' y='3.41'/%3E%3Cuse xlink:href='%23s7' x='0.9025' y='5.115'/%3E%3Cuse xlink:href='%23s7' x='0.9025' y='6.82'/%3E%3Cuse xlink:href='%23s8' y='8.525'/%3E%3C/g%3E%3C/defs%3E%3Crect width='36.1' height='19' fill='%23BB133E'/%3E%3Cpath stroke='%23FFF' stroke-width='1.4615' d=' M0,2.19225H36.1 M0,5.11525H36.1 M0,8.03825H36.1 M0,10.96125H36.1 M0,13.88425H36.1 M0,16.80725H36.1'/%3E%3Crect width='14.44' height='10.23' fill='%23002664'/%3E%3Cuse xlink:href='%23u'/%3E%3C/svg%3E"></img>
            <Expire delay={1000}>{language === "de" ? "Sprache auf Deutsch umgestellt" : "Changed language to english"}</Expire>
        </Fragment>
    )
}


const Expire = (props: { delay: number; children: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; }) => {
    const [visible, setVisible] = useState(false);
    const [firstTime, setFirstTime] = useState(true);
    useEffect(() => {
        if (!firstTime) {
            setVisible(true)
            const timer = setTimeout(() => {
                setVisible(false);
            }, props.delay);
            return () => clearTimeout(timer)
        }
        else {
            setFirstTime(false);
        }
    }, [props.delay, props.children]);

    return visible ? <span>{props.children}</span> : <span />;
};
