import {css, CSSInterpolation} from "@emotion/css";

export function cssClasses<T extends string>(styles: Record<T, CSSInterpolation>) {
    const classes: Partial<Record<T, string>> = {};
    Object.entries<CSSInterpolation>(styles).forEach(([key, style]) => {
        classes[key as T] = css(style);
    });
    return classes as Record<T, string>;
}

export function delay<T>(value: T): Promise<T> {
    return new Promise((resolve) => {
        setTimeout(() => resolve(value), 2500);
    });
}
